export function menu(wrapper = "main-menu") {
    const mainNavigationWrapper = document.querySelector("." + wrapper);
    let parentNavigationItemsForMobile = mainNavigationWrapper.querySelectorAll(
        ".js-toggle-mobile-inner-nav"
    );
    parentNavigationItemsForMobile.forEach(function (item) {
        item.addEventListener("click", () => {
            if (
                item.parentElement.nextElementSibling.classList.contains(
                    "is-open"
                )
            ) {
                item.parentElement.nextElementSibling.classList.remove(
                    "is-open"
                );
                item.parentElement.nextElementSibling.removeAttribute("style");
                item.querySelector("div").classList.remove("opacity-0");
            } else {
                item.parentElement.nextElementSibling.classList.add("is-open");
                item.parentElement.nextElementSibling.style.height =
                    item.parentElement.nextElementSibling.scrollHeight + "px";
                item.querySelector("div").classList.add("opacity-0");
            }
        });
    });
}
