import $ from 'jquery';

window.jQuery = window.$ = $;

// Import our CSS
import "@/scss/app.scss";

// Internal
import { menu } from "./components/menu.js";

import './parts/magiczoom.js';
import './parts/jquery.validate.min.js';

import AOS from "aos";

// alpine
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(collapse);
window.Alpine = Alpine;
Alpine.start();

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});

// JS callback functions for:
// 1. Hamburger Menu toggle.
// 2. Show / Hide Mobile Menu (on hamburger button click)
menu();

$(function() {
    $(document).on('submit', 'form#search-dealer-form', function(event) {
        event.preventDefault();

        var _this = $(this);
        _this.find('.loader').show();

        $.post(_this.attr('action'), _this.serialize(), function(data, textStatus, xhr) {
            _this.find('.loader').hide();
            $('.results-wrapper').find(".dealer-content").html(data);
            $('.table-border').show();
        });
    });
});

